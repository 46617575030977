import Base from './Base.js';

export default function BudgetRecurrenceTypeCreator() {
  class BudgetRecurrenceType extends Base {}

  BudgetRecurrenceType.initEnum({
    DAYS: {
      value: 'DAYS',
      display: 'Days(s)',
    },
    WEEKS: {
      value: 'WEEKS',
      display: 'Week(s)',
    },
  });

  return BudgetRecurrenceType;
}
