const ssoCallback = {
  path: '/sso/callback',
  name: 'ssoCallback',
  meta: {
    bodyClass: 'auth-views',
    storeModules: ['authorization'],
  },
  component: () => import('@/views/auth/SSO.vue'),
};

const ssoLink = {
  path: '/sso/link/:token',
  name: 'ssoLink',
  meta: {
    bodyClass: 'auth-views',
    storeModules: ['authorization'],
  },
  component: () => import('@/views/auth/SSOLink.vue'),
};

export { ssoLink, ssoCallback };

