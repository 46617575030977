<template>
  <div ref="assetServiceDetail" class="asset-service-detail">
    <div v-if="loading">
      <PrimeSkeleton height="10rem" />
    </div>

    <div v-else>
      <b-alert v-if="errors && errors.length > 0" variant="danger" show>
        <div class="font-weight-bold">{{ $t('views.login.error') }}</div>
        <ul>
          <li v-for="(error, index) in errors" :key="index">
            <span :key="error">{{ error }}</span>
          </li>
        </ul>
      </b-alert>

      <div class="status mt-4">
        <div v-if="isDraft">
          <b-badge variant="info" class="status-badge">Draft</b-badge>
          <div class="small text-muted d-inline-block">You can continue to make changes to this service detail.</div>
        </div>
        <div v-else-if="isSubmitted">
          <b-badge variant="warning" class="status-badge">Submitted</b-badge>
          <div class="small text-muted d-inline-block">You are unable make changes to submitted service details.</div>
        </div>
        <div v-else-if="isApproved">
          <b-badge variant="success" class="status-badge">Approved</b-badge>
          <div class="small text-muted d-inline-block">This service detail was approved and the changes have been applied to the asset.</div>
        </div>
        <div v-else-if="isRejected">
          <b-badge variant="danger" class="status-badge">Rejected</b-badge>
          <div class="small text-muted d-inline-block">This service detail was rejected.</div>
        </div>
        <div v-else>
          <b-alert show variant="warning">
            This asset does not have a service detail yet. Complete the form below to create a service detail.
          </b-alert>
        </div>
      </div>

      <div class="mt-4 mb-4">
        <SingleAsset
          :asset="asset"
          :can-select="false"
          :include-badges="false"
          :include-warranty="false"
          class="bg-light"
        />
      </div>

      <FormGroup
        v-slot="slotProps"
        label="Has this asset been serviced?"
        :field="v$.form.serviced"
      >
        <b-form-select
          v-bind="slotProps"
          v-model="v$.form.serviced.$model"
          :options="servicedOptions"
          :disabled="readOnly"
          value-field="value"
          text-field="display"
          name="serviced"
          @keydown.enter="onEnterPress"
        >
          <template #first>
            <option :value="null">
              {{ $t('common.forms.selectAnOption') }}
            </option>
          </template>
        </b-form-select>
      </FormGroup>

      <FormGroup
        v-slot="slotProps"
        label="Service comments"
        :field="v$.form.comments"
      >
        <b-form-textarea
          v-bind="slotProps"
          v-model="v$.form.comments.$model"
          :disabled="readOnly"
          name="comments"
          rows="3"
          @keydown.enter="onEnterPress"
        />
      </FormGroup>

      <div v-if="serviced">
        <b-card header="Status" class="field">
          <div class="row">
            <div class="col-md-6 mb-md-0 mb-3">
              <FormGroup
                v-slot="slotProps"
                :label="originalLabel"
                :description="originalAssetStatusDescription"
                :field="v$.originals.originalAssetStatus"
              >
                <b-form-input
                  v-bind="slotProps"
                  v-model="v$.originals.originalAssetStatus.$model"
                  disabled
                  name="originalAssetStatus"
                />
              </FormGroup>
            </div>
            <div class="col-md-6">
              <FormGroup
                v-slot="slotProps"
                :label="recommendationLabel"
                :field="v$.form.recommendedAssetStatus"
              >
                <b-form-select
                  v-bind="slotProps"
                  v-model="v$.form.recommendedAssetStatus.$model"
                  :options="assetStatuses"
                  :disabled="readOnly"
                  value-field="id"
                  text-field="status"
                  name="recommendedAssetStatus"
                  @keydown.enter="onEnterPress"
                >
                  <template #first>
                    <option :value="null">
                      {{ noChangeLabel }}
                    </option>
                  </template>
                </b-form-select>
              </FormGroup>
            </div>
          </div>
        </b-card>

        <b-card header="Condition" class="field">
          <div class="row">
            <div class="col-md-6 mb-md-0 mb-3">
              <FormGroup
                v-slot="slotProps"
                :label="originalLabel"
                :description="originalConditionGradeDescription"
                :field="v$.originals.originalConditionGrade"
              >
                <b-form-input
                  v-bind="slotProps"
                  v-model="v$.originals.originalConditionGrade.$model"
                  disabled
                  name="originalConditionGrade"
                />
              </FormGroup>
            </div>
            <div class="col-md-6">
              <FormGroup
                v-slot="slotProps"
                :label="recommendationLabel"
                :field="v$.form.recommendedConditionGrade"
              >
                <b-form-select
                  v-bind="slotProps"
                  v-model="v$.form.recommendedConditionGrade.$model"
                  :options="conditionGrades"
                  :disabled="readOnly"
                  value-field="id"
                  text-field="name"
                  name="recommendedConditionGrade"
                  @keydown.enter="onEnterPress"
                >
                  <template #first>
                    <option :value="null">
                      {{ noChangeLabel }}
                    </option>
                  </template>
                </b-form-select>
              </FormGroup>
            </div>
          </div>
        </b-card>

        <b-card header="Warranty Labour" class="field">
          <div class="row">
            <div class="col-md-6 mb-md-0 mb-3">
              <FormGroup
                v-slot="slotProps"
                :label="originalLabel"
                :description="originalWarrantyLabourDescription"
                :field="v$.originals.warranty_labour_original"
              >
                <DatePicker
                  v-bind="slotProps"
                  v-model="v$.originals.warranty_labour_original.$model"
                  type="date"
                  name="warranty_labour_original"
                  :placeholder="$t('common.forms.none')"
                  :end-of-day="true"
                  :timezone-sensitive="false"
                  :read-only="true"
                />
              </FormGroup>
            </div>
            <div class="col-md-6">
              <FormGroup
                v-slot="slotProps"
                :label="recommendationLabel"
                :field="v$.form.warranty_labour_recommendation"
              >
                <DatePicker
                  v-bind="slotProps"
                  v-model="v$.form.warranty_labour_recommendation.$model"
                  type="date"
                  name="warranty_labour_recommendation"
                  :placeholder="this.form.warranty_labour_recommendation ? null : noChangeLabel"
                  :end-of-day="true"
                  :timezone-sensitive="false"
                  :read-only="readOnly"
                  allow-empty
                />
              </FormGroup>
            </div>
          </div>
        </b-card>

        <b-card header="Warranty Parts" class="field">
          <div class="row">
            <div class="col-md-6 mb-md-0 mb-3">
              <FormGroup
                v-slot="slotProps"
                :label="originalLabel"
                :description="originalWarrantyPartsDescription"
                :field="v$.originals.warranty_parts_original"
              >
                <DatePicker
                  v-bind="slotProps"
                  v-model="v$.originals.warranty_parts_original.$model"
                  type="date"
                  name="warranty_parts_original"
                  :placeholder="$t('common.forms.none')"
                  :end-of-day="true"
                  :timezone-sensitive="false"
                  :read-only="true"
                />
              </FormGroup>
            </div>
            <div class="col-md-6">
              <FormGroup
                v-slot="slotProps"
                :label="recommendationLabel"
                :field="v$.form.warranty_parts_recommendation"
              >
                <DatePicker
                  v-bind="slotProps"
                  v-model="v$.form.warranty_parts_recommendation.$model"
                  type="date"
                  name="warranty_parts_recommendation"
                  :placeholder="this.form.warranty_parts_recommendation ? null : noChangeLabel"
                  :end-of-day="true"
                  :timezone-sensitive="false"
                  :read-only="readOnly"
                  allow-empty
                />
              </FormGroup>
            </div>
          </div>
        </b-card>
      </div>

      <AssetServiceDetailSchedulesAlert
        v-if="isSubmitted && showApprovalButtons"
        :original-asset-status-id="serviceDetail.originalAssetStatus.id"
        :recommended-asset-status-id="serviceDetail.recommendedAssetStatus?.id"
        class="mt-2"
      />

      <div class="d-flex justify-content-between mt-2">
        <b-button
          v-if="showSaveButton"
          variant="primary"
          class="mr-2"
          block
          :disabled="loading || v$.$invalid"
          @click="onSave"
        >
          Save
        </b-button>

        <b-button
          v-if="showApprovalButtons"
          variant="success"
          class="mr-2 btn-block mt-0"
          :disabled="loading"
          @click="onReview(true)"
        >
          Approve All
        </b-button>

        <b-button
          v-if="showApprovalButtons"
          variant="danger"
          class="ml-2 btn-block mt-0"
          :disabled="loading"
          @click="onReview(false)"
        >
          Reject All
        </b-button>
      </div>
    </div>

  </div>
</template>

<script>
import {BAlert, BBadge, BButton, BCard, BFormInput, BFormSelect, BFormTextarea} from "bootstrap-vue";
import IndexAPI from "@/services/v2/IndexAPI";
import IndexConfig from "@/services/v2/IndexConfig";
import PatchAPI from "@/services/v2/PatchAPI";
import PostAPI from "@/services/v2/PostAPI";
import Errors from "@/services/v2/Errors";
import PrimeSkeleton from "primevue/skeleton/Skeleton.vue";
import useVuelidate from "@vuelidate/core";
import {required, requiredIf} from "@vuelidate/validators";
import FormGroup from "@/ux/form/FormGroup.vue";
import DatePicker from "@/ux/form/DatePicker.vue";
import PostConfig from "@/services/v2/PostConfig";
import PatchConfig from "@/services/v2/PatchConfig";
import AssetServiceDetailSchedulesAlert
  from "@/components/asset/AssetServiceDetailSchedulesAlert.vue";

export default {
  name: 'AssetServiceDetailForm',
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    AssetServiceDetailSchedulesAlert,
    BAlert,
    DatePicker,
    BCard,
    BFormInput,
    BFormSelect,
    FormGroup, BFormTextarea,
    PrimeSkeleton,
    BButton,
    BBadge,
  },
  props: {
    asset: {
      type: Object,
      required: true,
    },
    workOrder: {
      type: Object,
      required: true,
    },
    serviceDetail: {
      type: Object,
      default: null,
    },
    loadedAssetStatuses: {
      type: Array,
      default: () => [],
    },
    loadedConditionGrades: {
      type: Array,
      default: () => [],
    },
    approve: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'updated',
  ],
  data() {
    return {
      loading: true,
      errors: [],
      form: {},
      originals: {},
      servicedOptions: [
        { value: true, display: 'Yes' },
        { value: false, display: 'No' },
      ],
      assetStatuses: [],
      conditionGrades: [],

      noChangeLabel: "-- No change --",
      originalLabel: 'Current',
      recommendationLabel: 'Recommendation',
    };
  },
  computed: {
    originalAssetStatusChanged() {
      return this.serviceDetail
        && !this.isApproved
        && !this.isRejected
        && this.serviceDetail.originalAssetStatus
        && this.asset.assetStatus.id !== this.serviceDetail.originalAssetStatus.id;
    },
    originalAssetStatusDescription() {
      return this.originalAssetStatusChanged
        ? this.getOriginalDescription(this.asset.assetStatus.status)
        : null;
    },
    originalConditionGradeChanged() {
      return this.serviceDetail
        && !this.isApproved
        && !this.isRejected
        && this.serviceDetail.originalConditionGrade
        && this.asset.conditionGrade.id !== this.serviceDetail.originalConditionGrade.id;
    },
    originalConditionGradeDescription() {
      return this.originalConditionGradeChanged
        ? this.getOriginalDescription(this.asset.conditionGrade.name)
        : null;
    },
    originalWarrantyPartsChanged() {
      return this.serviceDetail
        && !this.isApproved
        && !this.isRejected
        && this.serviceDetail.warranty_parts_original
        && this.asset.warranty_parts !== this.serviceDetail.warranty_parts_original;
    },
    originalWarrantyPartsDescription() {
      return this.originalWarrantyPartsChanged
        ? this.getOriginalDescription(this.$formatDate(this.asset.warranty_parts))
        : null;
    },
    originalWarrantyLabourChanged() {
      return this.serviceDetail
        && !this.isApproved
        && !this.isRejected
        && this.serviceDetail.warranty_labour_original
        && this.asset.warranty_labour !== this.serviceDetail.warranty_labour_original;
    },
    originalWarrantyLabourDescription() {
      return this.originalWarrantyLabourChanged
        ? this.getOriginalDescription(this.$formatDate(this.asset.warranty_labour))
        : null;
    },
    serviced() {
      return this.form.serviced === true;
    },
    readOnly() {
      return this.approve
        || this.loading
        || (this.serviceDetail && !this.isDraft);
    },
    isServiced() {
      return this.serviceDetail?.serviced;
    },
    isDraft() {
      return this.serviceDetail?.status === this.$enums.ASSET_SERVICE_DETAIL_STATUS.DRAFT.value;
    },
    isSubmitted() {
      return this.serviceDetail?.status === this.$enums.ASSET_SERVICE_DETAIL_STATUS.SUBMITTED.value;
    },
    isApproved() {
      return this.serviceDetail?.status === this.$enums.ASSET_SERVICE_DETAIL_STATUS.APPROVED.value;
    },
    isRejected() {
      return this.serviceDetail?.status === this.$enums.ASSET_SERVICE_DETAIL_STATUS.REJECTED.value;
    },
    showSaveButton() {
      return !this.readOnly;
    },
    showApprovalButtons() {
      return this.approve
        && this.isServiced
        && this.isSubmitted;
    },
  },
  validations() {
    return {
      form: {
        serviced: {
          required,
        },
        comments: {
          required: requiredIf(() => !this.form.serviced),
        },
        recommendedAssetStatus: {},
        recommendedConditionGrade: {},
        warranty_parts_recommendation: {},
        warranty_labour_recommendation: {},
      },
      originals: {
        originalAssetStatus: {},
        originalConditionGrade: {},
        warranty_parts_original: {},
        warranty_labour_original: {},
      },
    };
  },
  watch: {
    serviceDetail: {
      async handler() {
        await this.setup();
      }
    },
  },
  async created() {
    await this.setup();
  },
  methods: {
    async setup() {
      this.loading = true;

      return Promise.all([
        await this.fetchRelatedData(),
        await this.setupFormData(),
      ]).then(() => {
        this.loading = false;
      });
    },
    async setupFormData() {
      this.loading = true;

      this.form = {
        'serviced': this.serviceDetail?.serviced ?? null,
        'comments': this.serviceDetail?.comments ?? null,
        'recommendedAssetStatus': this.serviceDetail?.recommendedAssetStatus?.id ?? null,
        'recommendedConditionGrade': this.serviceDetail?.recommendedConditionGrade?.id ?? null,
        'warranty_parts_recommendation': this.serviceDetail?.warranty_parts_recommendation ?? null,
        'warranty_labour_recommendation': this.serviceDetail?.warranty_labour_recommendation ?? null,
      }
      this.originals = {
        'originalAssetStatus': this.serviceDetail?.originalAssetStatus?.status
            ? this.serviceDetail.originalAssetStatus.status
            : this.asset?.assetStatus?.status,
        'originalConditionGrade': this.serviceDetail?.originalConditionGrade?.name
            ? this.serviceDetail.originalConditionGrade.name
            : this.asset?.conditionGrade?.name,
        'warranty_parts_original': this.serviceDetail?.warranty_parts_original
            ? this.serviceDetail.warranty_parts_original
            : this.asset?.warranty_parts,
        'warranty_labour_original': this.serviceDetail?.warranty_labour_original
            ? this.serviceDetail.warranty_labour_original
            : this.asset?.warranty_labour,
      }

      this.v$.$reset();

      this.loading = false;
    },
    async fetchRelatedData() {
      await this.fetchAssetStatuses();
      await this.fetchConditionGrades();
    },
    async fetchAssetStatuses() {
      if (this.loadedAssetStatuses.length) {
        this.assetStatuses = this.loadedAssetStatuses;
        return;
      }

      const response = await IndexAPI.fetch(
        'asset-statuses',
        (new IndexConfig())
          .setFields({
            'asset-statuses': [
              'status',
            ],
          })
          .setSort('id')
      );

      this.assetStatuses = response.data;
    },
    async fetchConditionGrades() {
      if (this.loadedConditionGrades.length) {
        this.conditionGrades = this.loadedConditionGrades;
        return;
      }

      const response = await IndexAPI.fetch(
        'condition-grades',
        (new IndexConfig())
          .setFields({
            'condition-grades': [
              'name',
            ],
          })
          .setSort('order')
      );

      this.conditionGrades = response.data;
    },
    onEnterPress() {
      this.v$.$validate();

      if (!this.v$.$invalid) {
        this.onSave();
      }
    },
    async onSave() {
      this.loading = true;
      this.errors = [];

      try {
        if (this.serviceDetail) {
          await PatchAPI.patch(
            'asset-service-details',
            (new PatchConfig(this.serviceDetail.id))
              .setData(this.form),
          );
        } else {
          await PostAPI.post(
            'asset-service-details',
            (new PostConfig())
              .setData({
                asset: this.asset.id,
                workOrder: this.workOrder.id,
                status: this.$enums.ASSET_SERVICE_DETAIL_STATUS.DRAFT.value,
                ...this.form,
              }),
          );
        }
      } catch (error) {
        this.errors = Errors.getErrors(error);
      }

      if (!this.errors.length) {
        this.$emit('updated');
      } else {
        this.$refs.assetServiceDetail.scrollTop;
      }

      this.loading = false;
    },
    async onReview(approve) {
      this.loading = true;
      this.errors = [];

      try {
        await PatchAPI.patch(
          'asset-service-details',
          (new PatchConfig(this.serviceDetail.id))
            .setData({
              status: approve
                ? this.$enums.ASSET_SERVICE_DETAIL_STATUS.APPROVED.value
                : this.$enums.ASSET_SERVICE_DETAIL_STATUS.REJECTED.value,
            }),
        );
      } catch (error) {
        this.errors = Errors.getErrors(error);
      }

      if (!this.errors.length) {
        this.$emit('updated');
      } else {
        this.$refs.assetServiceDetail.scrollTop;
      }

      this.loading = false;
    },
    getOriginalDescription(newValue, date = false) {
      const displayValue = newValue
        ? date ? this.$formatDate(newValue) : newValue
        : this.$t('common.forms.none');

      return `This was the value when the service detail was created, but has since been changed on the asset to ${ displayValue }.`;
    },
  },
}
</script>

<style scoped lang="scss">
.status {
  .status-badge {
    font-size: 1rem;
    margin-right: .5rem;
  }
}

:deep(label) {
  color: #6c757d;
}

:deep(.card.field) {
  margin: 1.5rem 0;
  border-color: rgba(0, 0, 0, 0.2);

  .card-header {
    color: #6c757d;
    font-weight: 500;
    padding: 0.35rem 1.25rem;
  }

  .card-body {
    padding: .75rem 1.25rem;

    .form-group {
      margin-bottom: 0;
    }

    .form-text {
      color: #EE4B3A !important;
    }

    label {
      font-size: .85rem;
    }
  }
}
</style>
