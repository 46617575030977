<template>
  <div v-if="showAlert">
    <b-alert
      variant="warning"
      show
      class="small"
    >
      <div v-if="showCreateMessage">
        The recommended asset status may result in this asset being <strong>attached</strong> to any relevant planned work schedules.
      </div>
      <div v-if="showRemoveMessage">
        The recommended asset status will result in this asset being <strong>detached</strong> from any relevant planned work schedules.
      </div>
    </b-alert>
  </div>
</template>

<script>
import {BAlert} from "bootstrap-vue";
import IndexAPI from "@/services/v2/IndexAPI";
import IndexConfig from "@/services/v2/IndexConfig";

export default {
  name: 'AssetServiceDetailSchedulesAlert',
  components: {BAlert},
  props: {
    originalAssetStatusId: {
      type: Number,
      required: true
    },
    recommendedAssetStatusId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      loading: true,
      assetStatuses: [],
    };
  },
  computed: {
    showAlert() {
      if (this.loading) {
        return false;
      }

      return this.showCreateMessage
        || this.showRemoveMessage;
    },
    showCreateMessage() {
      return this.assetStatusChanged
        && !this.statusIsUsableOnPlannedWorkTemplates(this.originalAssetStatusId)
        && this.statusIsUsableOnPlannedWorkTemplates(this.recommendedAssetStatusId);
    },
    showRemoveMessage() {
      return this.assetStatusChanged
        && !this.statusIsUsableOnPlannedWorkTemplates(this.recommendedAssetStatusId);
    },
    assetStatusChanged() {
      return this.recommendedAssetStatusId
        && this.originalAssetStatusId !== this.recommendedAssetStatusId;
    },
  },
  async created() {
    return Promise.all([
      await this.fetchAssetStatuses(),
    ]).finally(() =>{
      this.loading = false;
    });
  },
  methods: {
    async fetchAssetStatuses() {
      const response = await IndexAPI.fetch(
        'asset-statuses',
        (new IndexConfig())
          .setFields({
            'asset-statuses': [
              'status',
              'usable_on_planned_work_templates',
            ],
          })
      );

      this.assetStatuses = response.data;
    },
    statusIsUsableOnPlannedWorkTemplates(assetStatusId) {
      if (!assetStatusId) {
        return false;
      }

      return this.assetStatuses
        .find((status) => status.id === assetStatusId)
        ?.usable_on_planned_work_templates;
    },
  },
}
</script>

<style scoped lang="scss">

</style>
