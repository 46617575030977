import Base from './Base.js';

export default function WorkOrderTimeLogSourceCreator() {
  class WorkOrderTimeLogSource extends Base {}

  WorkOrderTimeLogSource.initEnum({
    CORE_APP: {
      value: 'CORE_APP',
      display: 'Core App',
    },
    ENGINEER_APP: {
      value: 'ENGINEER_APP',
      display: 'Engineer App',
    },
  });

  return WorkOrderTimeLogSource;
}
