import Base from './Base.js';

export default function PlannedWorkTemplateTypeCreator() {
  class PlannedWorkTemplateType extends Base {}

  PlannedWorkTemplateType.initEnum({
    LOCATION: {
      value: 'LOCATION',
      display: 'Location',
    },
    ASSET: {
      value: 'ASSET',
      display: 'Asset',
    },
  });

  return PlannedWorkTemplateType;
}
