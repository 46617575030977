import Base from './Base.js';

export default function PlannedWorkTemplateFrequencyCreator(i18n = null) {
  class PlannedWorkTemplateFrequency extends Base {}

  PlannedWorkTemplateFrequency.initEnum({
    DAILY: {
      value: 'DAILY',
      display: 'Day(s)',
    },
    WEEKLY: {
      value: 'WEEKLY',
      display: 'Week(s)',
    },
    MONTHLY: {
      value: 'MONTHLY',
      display: 'Month(s)',
    },
    YEARLY: {
      value: 'YEARLY',
      display: 'Year(s)',
    },
  });

  return PlannedWorkTemplateFrequency;
}
