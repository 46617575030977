<template>
  <PrimeSidebar
    ref="sidebar"
    v-bind="$attrs"
    v-model:visible="visible"
    :position="position"
    :pt="{
      root: {
        class: [
          'prime-side-panel',
          rootClass ?? 'responsive-width',
          size,
          depthClass,
        ]
      },
    }"
    @hide="$emit('hide')"
  >
    <template #header>
      <slot name="header" />
    </template>

    <slot></slot>
  </PrimeSidebar>
</template>

<script>
import PrimeSidebar from 'primevue/sidebar/Sidebar.vue';

export default {
  name: 'SidePanel',
  components: {
    PrimeSidebar,
  },
  props: {
    position: {
      type: String,
      default: 'right',
    },
    rootClass: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
      validator(value) {
        // could extend this for other sizes in future...
        return ['large', 'extra-large'].includes(value)
      }
    },
  },
  emits: [
    'hide',
  ],
  data() {
    return {
      visible: false,
      depth: 0,
    };
  },
  computed: {
    depthClass() {
      return `depth-${this.depth}`;
    }
  },
  methods: {
    async show() {
      this.depth = document.querySelectorAll('.p-sidebar-mask.p-sidebar-visible').length;

      await this.$nextTick(() => {
        this.visible = true;
      });
    },
    hide() {
      this.visible = false;
    },
    async toggle() {
      if (!this.visible) {
        return this.show();
      }

      this.hide();
    },
  },
};
</script>

<style lang="scss">
.prime-side-panel {
  &.responsive-width {
    @include media-breakpoint-down(sm) {
      &.depth-0 {
        &.extra-large {
          width: 100% !important;
        }
        width: 90% !important;
      }

      &.depth-1 {
        width: calc(90% - 1rem) !important;
      }

      &.depth-2 {
        width: calc(90% - 2rem) !important;
      }
    }
    @include media-breakpoint-up(sm) {
      &.depth-0 {
        &.extra-large {
          width: 90% !important;
        }
        width: 75% !important;
      }

      &.depth-1 {
        width: calc(75% - 1rem) !important;
      }

      &.depth-2 {
        width: calc(75% - 2rem) !important;
      }
    }
    @include media-breakpoint-up(lg) {
      &.depth-0 {
        &.large {
          width: 65% !important;
        }
        &.extra-large {
          width: 80% !important;
        }
        width: 50% !important;
      }

      &.depth-1 {
        width: calc(50% - 1rem) !important;
      }

      &.depth-2 {
        width: calc(50% - 2rem) !important;
      }
    }
    @include media-breakpoint-up(xl) {
      &.depth-0 {
        &.large {
          width: 50% !important;
        }
        &.extra-large {
          width: 65% !important;
        }
        width: 33% !important;
      }

      &.depth-1 {
        width: calc(33% - 1rem) !important;
      }

      &.depth-2 {
        width: calc(33% - 2rem)!important;
      }
    }
  }
}
</style>

