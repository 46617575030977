<template>
  <div>
    <b-modal
      v-if="!showAsSidePanel"
      id="tree-field-modal"
      ref="modal"
      dialog-class="tree-field-modal expansive-modal full-height"
      scrollable
      size="lg"
      :hide-footer="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      v-on="$listeners"
    >
      <template v-slot:modal-header>
        <div class="d-flex w-100 align-items-center">
          <b-button variant="none" @click="onBackButtonClick">
            <font-awesome-icon :icon="['far', 'chevron-left']" size="lg" />
          </b-button>
          <span class="flex-fill text-center">
            <span :key="displayTitle">{{ displayTitle }}</span>
          </span>

          <b-button variant="none" @click="onCloseButtonClick">
            <font-awesome-icon :icon="['far', 'times']" size="lg" />
          </b-button>
        </div>
      </template>

      <div class="position-relative h-100">
        <List
          ref="list"
          :node="rootNode"
          :parent-select="parentSelect"
          :root-select="rootSelect"
          :children-field="childrenField"
          :allow-search="true"
          @nodechange="onNodeChange"
          @nodeselected="onNodeSelect"
          @search-change="onSearchChange"
        />
      </div>
    </b-modal>

    <SidePanel
      v-else
      ref="modal"
    >
      <template #header>
        <div class="d-flex w-100 align-items-center">
          <b-button variant="none" @click="onBackButtonClick">
            <font-awesome-icon :icon="['far', 'chevron-left']" size="lg" />
          </b-button>
          <span class="flex-fill text-center">
            <span :key="displayTitle">{{ displayTitle }}</span>
          </span>
        </div>
      </template>

      <div class="position-relative h-100">
        <List
          ref="list"
          :node="rootNode"
          :parent-select="parentSelect"
          :root-select="rootSelect"
          :children-field="childrenField"
          :allow-search="true"
          @nodechange="onNodeChange"
          @nodeselected="onNodeSelect"
          @search-change="onSearchChange"
        />
      </div>
    </SidePanel>
  </div>
</template>

<script>
import { VBModal, BModal, BButton } from 'bootstrap-vue';
import List from '@/ux/form/list/List.vue';
import SidePanel from "@/ux/SidePanel.vue";

/**
 * This component is a modal with a 'drilldown list' collection where the tree of items
 * can be navigated and a leaf node selected.
 * It works by holding two lists which are populated as a navigation event happens. They alternate
 * being the entering list (i.e. if list one is visible then list two is populated
 * and shown and vice versa). The direction of the animation is determined by the `direction`
 * property and the list that is entering.
 * @exports src/ux/form/TreeFieldModal
 * @property value {number} The ID of the current selection
 * @property title {string} The modal's title on the first list (i.e. before an option
 * has been selected)
 * @property items {object[]} An array of items that form the tree structure
 * @property {string} valueField The field of the items to use as the value. Defaults to `id`
 * @property {string} displayField The field of the items to use for display
 * @property {boolean} parentSelect If true this allows users to select a 'folder' (via
 * a 'This Level' list option), as well as selecting a leaf. If false, only a leaf can be selected.
 */
export default {
  name: 'TreeFieldModal',

  components: {
    'b-modal': BModal,
    'b-button': BButton,
    List,
    SidePanel,
  },

  directives: {
    'b-modal': VBModal,
  },

  props: {
    value: {
      type: [Number, String, Object],
      default: () => '',
    },

    title: {
      type: String,
      default: () => this.$t('ux.form.TreeFieldModal.defaultTitle'),
    },

    items: {
      type: Array,
      default: () => [],
    },

    valueField: {
      type: String,
      default: () => 'id',
    },

    displayField: {
      type: String,
      default: () => 'name',
    },

    parentSelect: {
      type: Boolean,
      default: true,
    },

    rootSelect: {
      type: Boolean,
      default: false,
    },

    childrenField: {
      type: [String, Function],
      default: () => 'children',
    },

    rootNodeName: {
      type: String,
      default: 'Root',
    },

    rootNodeReadOnly: {
      type: Boolean,
      default: false,
    },

    showDisabled: {
      type: Boolean,
      default: false,
    },
    showAsSidePanel: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      // mirrors the `value` prop and is used internally.
      innerValue: null,

      currentNode: null,

      hasSearch: false,
      searchResultCount: 0,
    };
  },

  computed: {
    // a dummy node so the tree has a true root.
    rootNode() {
      return {
        id: -1,
        name: this.rootNodeName,
        read_only: this.rootNodeReadOnly,
        children: this.filteredItems,
      };
    },

    filteredItems() {
      if (this.showDisabled) {
        return this.items;
      }
      function descend(item) {
        if (item.enabled === false) {
          return null;
        }

        return {
          ...item,
          children: item.children?.map(descend).filter((i) => i) || [],
        };
      }

      return this.items?.map(descend).filter((i) => i) || [];
    },

    // the title of the modal depending on the active list
    displayTitle() {
      const title = this.currentNode ? this.currentNode.name : this.title;

      return this.hasSearch ? this.searchTitle : title;
    },

    searchTitle() {
      return `${this.$t('common.searchResults')} (${this.searchResultCount})`;
    },
  },

  watch: {
    // if the `value` prop changes we sync the `innerValue`
    value() {
      this.cloneValue();
    },

    // if the items array changes then we reset the list
    items() {
      this.resetList();
    },
  },

  created() {
    this.cloneValue();
  },

  methods: {
    /**
     * Copies the `value` prop into the `innerValue` or the `defaultValue`
     * if `value` is falsey.
     * @method
     */
    cloneValue() {
      this.innerValue = this.value;
    },

    /**
     * @name [PUBLIC] Shows the modal
     * @method
     * @public
     */
    show() {
      this.resetList();

      this.$refs.modal.show();
    },

    resetList() {
      // eslint-disable-next-line no-unused-expressions
      this.$refs.list && this.$refs.list.resetList();
    },

    /**
     * @name [PUBLIC] Hides the modal
     * @method
     * @public
     */
    hide() {
      this.$refs.list.reset();

      this.$refs.modal.hide();
    },

    /**
     * Handler for the back button click.
     * This will use the `path` collection to move back a level.
     */
    onBackButtonClick() {
      if (!this.$refs.list.back()) {
        this.doClose();
      }
    },

    /**
     * Handler for a click on the close button.
     * This just hides the modal completely.
     */
    onCloseButtonClick() {
      this.doClose();
    },

    doClose() {
      this.hide();

      this.$emit('cancel');
    },

    /**
     * Handler for when a leaf node is clicked so we can update the underlying value
     * @param  {object} node The node to select
     */
    onNodeSelect(node) {
      this.innerValue = node;
      this.$emit('input', this.innerValue);
      this.$emit('done', this.innerValue);
    },

    onNodeChange(node) {
      this.currentNode = node;
    },

    onSearchChange(hasSearch, resultCount) {
      this.hasSearch = hasSearch;
      this.searchResultCount = resultCount;
    },
  },
};
</script>

<style lang="scss">
#tree-field-modal___BV_modal_outer_ {
  // To get the modal to appear when triggered from a side panel
  z-index: 999999999 !important;
}
</style>
