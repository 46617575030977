import axios from 'axios';
import createAPIParams, { processFilterParameters } from '@/util/createAPIParams.js';

/**
 * Interface to the MaintainableEntity API endpoints
 * @class
 * @exports src/services/MaintainableEntityAPI
 */
class MaintainableEntityAPI {
  /**
   * Retrieves the specified MaintainableEntity's details
   * @method
   * @static
   * @param  {string} maintainableEntityType The maintainableEntity type to load
   * @param  {number} maintainableEntityId The maintainableEntity id to load
   * @return {Promise} Promise that resolves to the {@link MaintainableEntity} details.
   */
  static async getMaintainableEntity(maintainableEntityType, maintainableEntityId) {
    const response = await axios({
      method: 'GET',
      url: `/maintainable_entity/${maintainableEntityType}/${maintainableEntityId}`,
      responseType: 'json',
    });
    return response?.data?.data;
  }

  /**
   * Retrieves all MaintainableEntities for the given page
   * @return {Promise} Promise that resolves to the api response.
   * In format: { maintainableEntity: {object[]}, meta: {object}, links: {object} }
   */
  static async getMaintainableEntitiesTree(filter = {}) {
    const response = await axios({
      method: 'GET',
      url: '/maintainable_entity/tree',
      responseType: 'json',
      params: {
        ...processFilterParameters(filter),
      },
    });

    return response.data;
  }

  static async getMaintainableEntitiesGeneric(filter) {
    const response = await axios({
      method: 'GET',
      url: '/maintainable_entity',
      responseType: 'json',
      params: {
        ...processFilterParameters(filter ?? {}),
        page: 'all',
      },
    });

    return response.data;
  }

  /**
   * Retrieves all MaintainableEntities for the given _external_ page - using the token supplied
   * @return {Promise} Promise that resolves to the api response.
   * In format: { maintainableEntity: {object[]}, meta: {object}, links: {object} }
   */
  static async getWorkOrderLocationsExternal(filter, accessToken) {
    const response = await axios({
      method: 'GET',
      url: `work_order_form/external/${accessToken}/locations`,
      responseType: 'json',
      params: {
        ...processFilterParameters(filter ?? {}),
      },
    });

    return response.data;
  }

  /**
   * Retrieves all MaintainableEntities Tree
   * @param {string} maintainableEntityType The maintainableEntity type to load
   * @param {Number} page The page to load
   * @param {String} sort The field to sort on
   * @param {String} direction The sort direction. Either 'ASC' or 'DESC'. Defaults to ASC.
   * @param {Object} filter An object with key value pairs of filter values.
   * @return {Promise} Promise that resolves to the api response.
   * In format: { maintainableEntity: {object[]}, meta: {object}, links: {object} }
   */
  static async getMaintainableEntities(maintainableEntityType, page, sort, direction, filter) {
    const response = await axios({
      method: 'GET',
      url: `/maintainable_entity${maintainableEntityType ? `/${maintainableEntityType}` : ''}`,
      responseType: 'json',
      params: createAPIParams(page, sort, direction, filter),
    });

    return response.data;
  }

  /**
   * @summary Hits the create MaintainableEntity endpoint.
   * @desc Sends the given maintainableEntity data to the create endpoint
   * @param {string} maintainableEntityType The maintainableEntity type to load
   * @param  {MaintainableEntity}  maintainableEntity A {@link MaintainableEntity} object to be saved
   * @return {Promise}      Resolves to the returned full MaintainableEntity object
   */
  static async createMaintainableEntity(maintainableEntityType, maintainableEntity) {
    const response = await axios({
      method: 'POST',
      url: `maintainable_entity/${maintainableEntityType}`,
      responseType: 'json',
      data: maintainableEntity,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the PATCH MaintainableEntity endpoint.
   * @desc Sends the specified _modified_ fields to the endpoint to be saved.
   * @param {string} maintainableEntityType The maintainableEntity type to load
   * @param  {String|Number}  maintainableEntityId The MaintainableEntity Id to save
   * @param  {object}  modifiedFields An object with a subset of the
   * {@link MaintainableEntity} fields that have been modified and should be saved.
   * @return {Promise}      Resolves to the returned full MaintainableEntity object
   */
  static async updateMaintainableEntity(
    maintainableEntityType,
    maintainableEntityId,
    modifiedFields,
  ) {
    const response = await axios({
      method: 'PATCH',
      url: `maintainable_entity/${maintainableEntityType}/${maintainableEntityId}`,
      responseType: 'json',
      data: modifiedFields,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the DELETE MaintainableEntity endpoint.
   * @param {string} maintainableEntityType The maintainableEntity type to load
   * @param  {String|Number}  maintainableEntityId The MaintainableEntity Id to delete
   * @return {Promise}
   */
  static async deleteMaintainableEntity(maintainableEntityType, maintainableEntityId) {
    await axios({
      method: 'DELETE',
      url: `maintainable_entity/${maintainableEntityType}/${maintainableEntityId}`,
      responseType: 'json',
    });

    return true;
  }
}

export default MaintainableEntityAPI;
