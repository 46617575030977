import axios from 'axios';
import createAPIParams from '@/util/createAPIParams.js';

/**
 * Interface to the Role API endpoints
 * @class
 * @exports src/services/RoleAPI
 */
class RoleAPI {
  /**
   * Retrieves all the Roles
   * @method
   * @static
   * @return {Promise}          Promise that resolves to an array of {@link Roles} items.
   */
  static async getRoles(page, sort, direction, filter) {
    const response = await axios({
      method: 'GET',
      url: 'role',
      responseType: 'json',
      params: {
        ...createAPIParams(page || 'all', sort, direction, filter),
        include: 'service_priorities',
      },
    });

    return response.data.data;
  }
}

export default RoleAPI;
