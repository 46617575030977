import {createRouter, createWebHistory} from 'vue-router';
import auth from './routes/auth.js';
import settings from './routes/settings.js';
import documents from './routes/documents.js';
import workOrder from './routes/workOrder.js';
import workOrderCalendar from './routes/workOrderCalendar.js';
import requestForService from './routes/requestForService.js';
import costRequest from './routes/costRequest.js';
import dashboard from './routes/dashboard.js';
import site from './routes/site.js';
import contractor from './routes/contractor.js';
import contractorProfile from './routes/contractorProfile.js';
import contractorRegistration from './routes/contractorRegistration.js';
import maintainableEntity from './routes/maintainableEntity.js';
import costProposal from './routes/costProposal.js';
import invoice from './routes/invoice.js';
import { resetPassword, changePassword } from './routes/password.js';
import externalWorkOrderForm from './routes/externalWorkOrderForm.js';
import noPermission from './routes/noPermission.js';
import releaseNotes from './routes/releaseNotes.js';
import login from './routes/login.js';
import costLines from './routes/costLines.js';
import accountSettings from './routes/accountSettings.js';
import complianceDocuments from './routes/complianceDocuments.js';
import parts from './routes/parts.js';
import asset from "./routes/asset.js";
import { ssoCallback, ssoLink } from './routes/sso.js';
import remedialActions from '@/router/routes/remedialActions';

// temp set of routes so they exist for the main nav
const dummyRoutes = ['AddWorkOrders', 'ChangePassword'].map((r) => ({
  name: r,
  path: `/${r}`,
}));

const importedRoutes = [
  auth,
  documents,
  releaseNotes,
  settings,
  workOrder,
  workOrderCalendar,
  requestForService,
  complianceDocuments,
  costRequest,
  dashboard,
  site,
  contractor,
  contractorProfile,
  contractorRegistration,
  maintainableEntity,
  costProposal,
  invoice,
  resetPassword,
  changePassword,
  costLines,
  externalWorkOrderForm,
  login,
  noPermission,
  accountSettings,
  parts,
  asset,
  ssoCallback,
  ssoLink,
  remedialActions,
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    ...importedRoutes,
    ...dummyRoutes,
    {
      path: '/',
      meta: {
        layout: 'DefaultLayout',
      },
      component: () => import('@/views/PageNotFound.vue'),
    },
  ],
});

const originalPush = router.push;
const originalReplace = router.replace;

router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

router.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

export default router;
