<template>
  <div>
    <b-input-group>
      <b-form-input
        id="password"
        ref='input'
        v-model.trim="v$.password.$model"
        :disabled="disabled"
        :type="showPassword ? 'text' : 'password'"
        :state="state"
        @keydown.enter="$emit('onEnterPress')"
      />
      <b-input-group-append>
        <b-button :disabled="disabled" variant="light" @click="onShowPasswordToggle">
          <font-awesome-icon v-if="!showPassword" :icon="['far', 'eye']" />
          <font-awesome-icon v-else :icon="['far', 'eye-slash']" />
        </b-button>
      </b-input-group-append>
    </b-input-group>

    <PasswordStrength
      v-if="showStrength"
      v-model="passwordStrengthPassed"
      :password="v$.password.$model"
      class="mt-2"
    />

    <div v-if="showInstructions" class="small text-muted d-block mt-1">
      Passwords must be at least 8 characters and meet our password strength requirements. Uncommon words are better and avoid repeated words and characters.
    </div>
  </div>
</template>

<script>
import {maxLength, minLength, required} from '@vuelidate/validators';
import { BButton, BFormInput, BInputGroup, BInputGroupAppend } from 'bootstrap-vue';
import useVuelidate from '@vuelidate/core';
import PasswordStrength from "@/ux/form/PasswordStrength.vue";

export default {
  name: 'Password',
  setup() {
    // scope to false to stop this validation bleeding up to parent components
    return { v$: useVuelidate({ $scope: false }) };
  },
  components: {
    PasswordStrength,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    valid: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showInstructions: {
      type: Boolean,
      default: true,
    },
    showStrength: {
      type: Boolean,
      default: true,
    },
    showFeedback: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'input',
    'update:valid',
    'onEnterPress',
  ],
  data() {
    return {
      showPassword: false,
      passwordStrengthPassed: false,
    };
  },
  computed: {
    password: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    isValid() {
      return !this.v$?.password?.$invalid
        && this.passwordStrengthPassed;
    },
    state() {
      if (!this.showFeedback) {
        return null;
      }

      return this.v$.password.$dirty
        ? this.isValid
        : null;
    },
  },
  validations() {
    return {
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(255),
      },
    };
  },
  watch: {
    isValid() {
      this.$emit('update:valid', this.isValid);
    },
  },
  methods: {
    onShowPasswordToggle() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style lang="scss">
</style>
