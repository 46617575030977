<template>
  <div>
    <div class="container-fluid py-2 bg-light small text-left">
      <!-- Assumed Service -->
      <div v-if="!serviceDetail" class="row justify-content-start align-items-center">
        <div class="col-auto">
          <b-badge variant="warning" class="serviced-badge">
            Service Assumed
          </b-badge>
        </div>
        <div class="col">
          There are no service details available and the work order has been completed, so it is assumed the asset was serviced.
        </div>
      </div>

      <!-- Actual Service -->
      <div v-else>
        <div
          v-b-toggle="`service-details-${serviceDetail.id}`"
          class="toggle-button row justify-content-start align-items-center"
        >
          <div class="col-auto">
            <div class="collapsed">
              <font-awesome-icon class="collapsed" :icon="['far', 'plus']" />
            </div>
            <div class="expanded">
              <font-awesome-icon class="expanded" :icon="['far', 'minus']" />
            </div>
          </div>
          <div class="col-auto">
            <b-badge v-if="isServiced" variant="info" class="serviced-badge">
              Serviced {{ $formatDate(serviceDetail.created_at) }}
            </b-badge>
            <b-badge v-else class="serviced-badge not-serviced">
              Not Serviced
            </b-badge>
          </div>
          <div class="col">
            <div class="d-inline-block mr-2">
              <div v-if="isDraft">
                Service details are in <strong>draft</strong> status.
              </div>
              <div v-if="isSubmitted && !isServiced">
                Service details have been submitted.
              </div>
              <div v-if="isSubmitted && isServiced">
                Service details are <strong>awaiting review</strong>.
              </div>
              <div v-else-if="isApproved">
                Service details were <strong>approved</strong> by {{ serviceDetail.reviewedBy?.name }} on {{ $formatDate(serviceDetail.reviewed_at) }}.
              </div>
              <div v-else-if="isRejected">
                Service details were <strong>rejected</strong> by {{ serviceDetail.reviewedBy?.name }} on {{ $formatDate(serviceDetail.reviewed_at) }}.
              </div>
            </div>
          </div>
          <div class="col-auto">
            <div v-if="isDraft" class="d-inline-block">
              <div class="action d-inline-block" @click.prevent.stop="onEditClick">
                Edit
              </div>
              <div class="d-inline-block mx-2">|</div>
              <div class="action d-inline-block" @click.prevent.stop="onSubmitClick">
                <div v-if="isServiced">Submit for Approval</div>
                <div v-else>Submit</div>
              </div>
            </div>
            <div v-if="isSubmitted && isServiced && canReview" class="d-inline-block">
              <div class="action d-inline-block" @click.prevent.stop="onApprove">
                Approve All
              </div>
              <div class="d-inline-block mx-2">|</div>
              <div class="action d-inline-block" @click.prevent.stop="onReject">
                Reject All
              </div>
            </div>
          </div>
        </div>
        <b-collapse
          :id="`service-details-${serviceDetail.id}`"
          class="mt-3 details"
        >
          <div class="row align-items-center">
            <div class="col-md-4 text-right">
              Serviced
            </div>
            <div class="col-md-8">
            <span class="highlight">
              {{ serviceDetail.serviced ? 'Yes' : 'No' }}
            </span>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-md-4 text-right">
              Comments
            </div>
            <div class="col-md-8">
            <span class="highlight">
              {{ serviceDetail.comments ?? $t('common.forms.none') }}
            </span>
            </div>
          </div>
          <div v-if="isServiced">
            <div class="row align-items-center">
              <div class="col-md-4 text-right">
                Status
              </div>
              <div class="col-md-8">
              <span class="highlight">
                {{ serviceDetail.originalAssetStatus.status }}
              </span>
                <font-awesome-icon :icon="['far', 'arrow-right']" class="mx-2" />
                <span class="highlight">
                {{ recommendedAssetStatus }}
              </span>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-md-4 text-right">
                Condition
              </div>
              <div class="col-md-8">
              <span class="highlight">
                {{ serviceDetail.originalConditionGrade.name }}
              </span>
                <font-awesome-icon :icon="['far', 'arrow-right']" class="mx-2" />
                <span class="highlight">
                {{ recommendedConditionGrade }}
              </span>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-md-4 text-right">
                Warranty Labour
              </div>
              <div class="col-md-8">
              <span class="highlight">
                {{ originalWarrantyLabour }}
              </span>
                <font-awesome-icon :icon="['far', 'arrow-right']" class="mx-2" />
                <span class="highlight">
                {{ recommendedWarrantyLabour }}
              </span>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-md-4 text-right">
                Warranty Parts
              </div>
              <div class="col-md-8">
              <span class="highlight">
                {{ originalWarrantyParts }}
              </span>
                <font-awesome-icon :icon="['far', 'arrow-right']" class="mx-2" />
                <span class="highlight">
                {{ recommendedWarrantyParts }}
              </span>
              </div>
            </div>
          </div>

          <div v-if="!isDraft" class="row align-items-center small mt-2">
            <div class="col-md-4 text-right">
              Submitted by
            </div>
            <div class="col-md-8">
              <div v-if="serviceDetail.submittedBy">
                {{ serviceDetail.submittedBy?.name }} on {{ $formatDate(serviceDetail.submitted_at) }}
              </div>
            </div>
          </div>
          <div v-if="isServiced && (isApproved || isRejected)" class="row align-items-center small">
            <div class="col-md-4 text-right">
              Reviewed by
            </div>
            <div class="col-md-8">
              <div v-if="serviceDetail.reviewedBy">
                {{ serviceDetail.reviewedBy?.name }} on {{ $formatDate(serviceDetail.reviewed_at) }}
              </div>
              <div v-else>
                Awaiting review
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>

    <AssetServiceDetailSchedulesAlert
      v-if="isSubmitted && canReview"
      :original-asset-status-id="serviceDetail.originalAssetStatus.id"
      :recommended-asset-status-id="serviceDetail.recommendedAssetStatus?.id"
    />

    <ConfirmationSidePanel
      ref="submitConfirmationSidePanel"
      title="Submit for Approval"
      confirm-label="Submit"
      @confirm="onSubmitConfirmed"
    >
      You are about to <strong>submit</strong> this service detail.
      After clicking submit you will be unable to make any further changes.
    </ConfirmationSidePanel>

    <ConfirmationSidePanel
      ref="approveConfirmationSidePanel"
      title="Approve Service Details"
      confirm-label="Approve"
      @confirm="onApproveConfirmed"
    >
      You are about to <strong>approve</strong> this service detail.
      After clicking approve you will be unable to make any further changes.
    </ConfirmationSidePanel>

    <ConfirmationSidePanel
      ref="rejectConfirmationSidePanel"
      title="Reject Service Details"
      confirm-label="Reject"
      @confirm="onRejectConfirmed"
    >
      You are about to <strong>reject</strong> this service detail.
      After clicking reject you will be unable to make any further changes.
    </ConfirmationSidePanel>
  </div>
</template>

<script>
import {BBadge, BCollapse, VBToggle} from "bootstrap-vue";
import {mapGetters} from "vuex";
import PatchAPI from "@/services/v2/PatchAPI";
import ConfirmationSidePanel from "@/ux/ConfirmationSidePanel.vue";
import PatchConfig from "@/services/v2/PatchConfig";
import AssetServiceDetailSchedulesAlert from "@/components/asset/AssetServiceDetailSchedulesAlert.vue";

export default {
  name: 'AssetServiceDetail',
  components: {
    ConfirmationSidePanel,
    BBadge,
    BCollapse,
    AssetServiceDetailSchedulesAlert,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    serviceDetail: {
      type: Object,
      default: null,
    },
  },
  emits: [
    'edit',
    'updated',
  ],
  data() {
    return {
      noChange: '<no change>',
    };
  },
  computed: {
    ...mapGetters(['hasPermission']),
    isServiced() {
      return this.serviceDetail?.serviced;
    },
    statusEnum() {
      return this.$enums.ASSET_SERVICE_DETAIL_STATUS[this.serviceDetail?.status];
    },
    isDraft() {
      return this.statusEnum === this.$enums.ASSET_SERVICE_DETAIL_STATUS.DRAFT;
    },
    isSubmitted() {
      return this.statusEnum === this.$enums.ASSET_SERVICE_DETAIL_STATUS.SUBMITTED;
    },
    isApproved() {
      return this.statusEnum === this.$enums.ASSET_SERVICE_DETAIL_STATUS.APPROVED;
    },
    isRejected() {
      return this.statusEnum === this.$enums.ASSET_SERVICE_DETAIL_STATUS.REJECTED;
    },
    recommendedAssetStatus() {
      return this.serviceDetail?.recommendedAssetStatus?.status || this.noChange;
    },
    recommendedConditionGrade() {
      return this.serviceDetail?.recommendedConditionGrade?.name || this.noChange;
    },
    originalWarrantyParts() {
      return this.serviceDetail?.warranty_parts_original
        ? this.$formatDate(this.serviceDetail.warranty_parts_original)
        : this.$t('common.forms.none');
    },
    recommendedWarrantyParts() {
      return this.serviceDetail?.warranty_parts_recommendation
        ? this.$formatDate(this.serviceDetail.warranty_parts_recommendation)
        : this.noChange;
    },
    originalWarrantyLabour() {
      return this.serviceDetail?.warranty_labour_original
        ? this.$formatDate(this.serviceDetail.warranty_labour_original)
        : this.$t('common.forms.none');
    },
    recommendedWarrantyLabour() {
      return this.serviceDetail?.warranty_labour_recommendation
        ? this.$formatDate(this.serviceDetail.warranty_labour_recommendation)
        : this.noChange;
    },
    canReview() {
      return this.hasPermission('Asset_Manage');
    },
  },
  methods: {
    async onEditClick() {
      this.$emit('edit', this.serviceDetail);
    },
    async onSubmitClick() {
      this.$refs.submitConfirmationSidePanel.show();
    },
    async onSubmitConfirmed() {
      await this.onSubmit();
      this.$emit('updated');
    },
    async onApprove() {
      this.$refs.approveConfirmationSidePanel.show();
    },
    async onApproveConfirmed() {
      await this.onReview(true);
      this.$emit('updated');
    },
    async onReject() {
      this.$refs.rejectConfirmationSidePanel.show();
    },
    async onRejectConfirmed() {
      await this.onReview(false);
      this.$emit('updated');
    },
    async onSubmit() {
      await PatchAPI.patch(
        'asset-service-details',
        (new PatchConfig(this.serviceDetail.id))
          .setData({
            status: this.$enums.ASSET_SERVICE_DETAIL_STATUS.SUBMITTED.value,
          }),
      );

      this.$emit('updated');
    },
    async onReview(approve) {
      await PatchAPI.patch(
        'asset-service-details',
        (new PatchConfig(this.serviceDetail.id))
          .setData({
            status: approve
              ? this.$enums.ASSET_SERVICE_DETAIL_STATUS.APPROVED.value
              : this.$enums.ASSET_SERVICE_DETAIL_STATUS.REJECTED.value,
          }),
      );

      this.$emit('updated');
    },
  },
}
</script>

<style scoped lang="scss">
.serviced-badge {
  font-size: .85rem;
  font-weight: normal;

  &.not-serviced {
    color: rgb(23, 162, 184);
    border: 1px solid rgb(23, 162, 184);
    background-color: #FFF;
  }
}
.toggle-button {
  .collapsed {
    display: none;
  }
  .expanded {
    display: inline-block;
  }
  &.collapsed {
    .collapsed {
      display: inline-block;
    }
    .expanded {
      display: none;
    }
  }
}
.details {
  .row {
    margin-top: .25rem;
    .highlight {
      display: inline-block;
      background-color: #ECECEC;
      padding: .1rem .5rem;
      margin: .1rem 0;
      border-radius: .25rem;
      font-size: .85rem;
    }
  }
}
.action {
  color: $primary;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
}
</style>
