import GetAPI from "@/services/v2/GetAPI";

class MetaExtractor
{
  static async extractMeta(responseData) {
    return responseData.map((item) => {
      const itemId = item.id;
      const relationshipsMeta = {};

      if (item.relationships) {
        for (const relationshipKey in item.relationships) {
          const relationship = item.relationships[relationshipKey];
          if (relationship.meta) {
            relationshipsMeta[relationshipKey] = {
              count: relationship.meta.count,
            };
          }
        }
      }

      return {
        id: itemId,
        relationships: relationshipsMeta,
      };
    });
  }

  static getRelationshipCountForItem(meta, id, relationship) {
    return Object.values(meta).find((meta) => parseInt(meta.id) === parseInt(id))
      ?.relationships?.[relationship]?.count ?? 0;
  }
}

export default MetaExtractor;
